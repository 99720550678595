import { useAnalytics } from '@/components/molecules/AnalyticsProvider';
import { Dialog } from '@/components/molecules/Dialog';
import { GOOGLE_TRACK_INFO } from '@/lib/constants';
import { formatPhoneNumberWithInputMask } from '@/lib/phoneFormatter';
import { VehicleListingType, VehicleType } from '@/lib/schema/inventory/types';
import { PhoneIcon } from '@heroicons/react/24/solid';

interface CallModalProps {
  open: boolean;
  onClose: () => void;
  product: VehicleType | VehicleListingType;
}

export const CallModal: React.FC<CallModalProps> = ({
  open,
  onClose,
  product,
}) => {
  const { dealer, listingId } = product;
  const { handleActionTracking } = useAnalytics();

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <div className="flex flex-col gap-l p-xl">
        {dealer && dealer.name && dealer.city && dealer.province ? (
          <div className="text-body2Light">
            Call: {dealer.name}, {dealer.city}, {dealer.province}
          </div>
        ) : (
          <div className="text-body2Light">No dealer info found</div>
        )}
        {dealer?.phone && (
          <div className="flex items-center gap-s">
            <PhoneIcon width={20} height={20} fill="#000000" />
            <a
              href={`tel: ${dealer.phone}`}
              onClick={() => {
                handleActionTracking({
                  ...GOOGLE_TRACK_INFO.callDealerButton,
                  pageTarget: dealer?.phone
                    ? `/vehicle/${listingId}?dealerPhone=${dealer.phone}`
                    : `/vehicle/${listingId}`,
                });
              }}
            >
              <span className="text-body1Medium text-blue-medium hover:text-blue-dark hover:underline">
                {formatPhoneNumberWithInputMask(dealer.phone.toString())}
              </span>
            </a>
          </div>
        )}
      </div>
    </Dialog>
  );
};
