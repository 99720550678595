export const getDefaultLeadMessage = ({
  name,
  year,
  make,
  model,
  trim,
}: {
  name?: string;
  year: string | number;
  make: string;
  model: string;
  trim: string;
}) => {
  return `Hi, ${name ? `my name is ${name}.` : ''} I am interested in this ${year} ${make} ${model} ${trim}. Is it still for sale?`;
};
